import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './i18n/locale/en/HOPLi18n.json';
import translationHE from './i18n/locale/he/HOPLi18n.json';
import translationAR from './i18n/locale/ar/HOPLi18n.json';
import translationRU from './i18n/locale/ru/HOPLi18n.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    he: {
        translation: translationHE
    },
    ar: {
        translation: translationAR
    },
    ru: {
        translation: translationRU
    }
};

export const getDirection = () => {
    if (getLocale() === "he" || getLocale() === "ar") {
        return "rtl";
    }
    return "ltr";
}

export const getLocale = () => {
    return i18n.language;
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "he",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;