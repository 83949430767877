import './App.css';
import HMain from './HMain'
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  return (
    <div className="App">
         
               <HMain t={t}/>
           
    </div>
  );
}

export default App;
