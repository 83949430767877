import React from 'react';
import { useState,useEffect } from "react";
import LangSelector from "./LangSelector";
import mainpic from ".//resources/IMG_2032.png";
import a1 from './resources/A_Moryles_cert_recommend.JPG';
import a2 from './resources/A_Inked_Jewish_Comitte_Proszowice_1945.jpg';
import grocery_store_ad from './resources/A_Shop ad Abram Moryles.jpg';
import Kielce_paper_1st_page from './resources/A_1st page of Kielce January 1930 paper.jpg';
import a5 from './resources/A_Hebrew_translation_of_recommendation_letter_by_Yosef.pdf';
import a6 from './resources/A_301_379_Aron_witness.pdf';
import original_Zubalski_letter from './resources/A_original_Zubalski_letter_in_Polish.pdf';
import translated_Zubalski_letter from './resources/A_Hebrew_translation_of_Zubalskis_letter.pdf';
import Lis_brothers_tomb from './resources/A_Lis_brothers_tomb.jpg';
import Proszowice_Jewish_cemetery from './resources/AT_Proszowice Jewish cemetery.JPG';
import b4 from './resources/A_Kamil_attic.jpg';
import b5 from './resources/A_Kamil_stairs_to_attic.jpg';
import b6 from './resources/A_Kamil_house_1.jpg';
import b7 from './resources/A_Kamil_house_2.jpg';
import b8 from './resources/A_Kamil_house_3.jpg';
 
import v3 from './resources/A3_bronia_tells_tobacco_farmer-20h57m50s.mp4';
import v2 from './resources/A2_Bronia_tells_moving_bet_places-20h53m16s.mp4';
import v1 from './resources/A1_Bronia_tells_kicked_out_Kotyzas_house-.mp4';
import v4 from './resources/A4_Bronia_tells_hole_in_dairy_barn-21h07m57s.mp4';
import v5 from './resources/A5_Bronia_tells_onions.mp4';
import v6 from './resources/A6_moniek_tells_aka_ambush-21h28m42s.mp4';
import v7 from './resources/A7_Moniek_tells_Yosef_escapes_from_being murdered_by_AKA.mp4';
import v8 from './resources/A8_Bronia_tells_kicked_out_of_Scwarczynski-21h25m09s.mp4';
import v9 from './resources/A9_Bronia_tells_sitting_in_tobacco_nets-21h39m56s.mp4';
import v10 from './resources/A10_bronia tells pssst 21h42m25s-.mp4';
import v11 from './resources/A11_Bronia_tells_way_explanations_21h59m42s.mp4';
import v12 from './resources/A12_Bronia_tells_about_her_as_a_messenger_bringing_foods.mp4';
import v13 from './resources/A13_Bronia_tells_heading_to_land_of_Israel-14h21m34s.mp4';
import v14 from './resources/A14_Moniek_tells_about_fighting_with_German_children-21h09m09s.mp4';
import c1 from './resources/A_Fruma_and_Yosef.jpg';
import c2 from './resources/A_Inked_Marriage registration of Abram and Sara Moryles 2.jpg';
import c3 from './resources/A_35.jpg';
import c4 from './resources/A_36.jpg';
import c5 from './resources/AT_20160510_172930_Hole_location.jpg';
import c6 from './resources/A_House_no_75_in_3_May.JPG';
import c7 from './resources/AT_Proszowice_church_DSC_0520.jpg';
import c8 from './resources/AT_DSC_0326_Moryles_house_loction_.jpg';
import c9 from './resources/A_DSC_0224_old_train_bridge.JPG';
import c10 from './resources/A_fields_along_whole_area_20160508_180417.jpg';
import c11 from './resources/A_ josef_moryles_displaced_person_registration.jpg';
import d1 from './resources/A_Yosef_and_Moniek_Landsberg_1947.jpg';
import d2 from './resources/A_Bronia_and_Moniek_1941.jpg';
import d3 from './resources/A_Bronia_Moniek_Yosef_Lood_1949.jpg';
import d4 from './resources/A_Bronia_Moniek_Lood_1949.jpg';
import e1 from './resources/A_Israel_Hayom_ 2_June_2023.jpg';


import backcover from "./resources/A_Printed_back_cover-c.pdf";
import frontcover from "./resources/A_Printed_front_cover2.jpg";
import first_chapter from "./resources/A_Undercow_first_chapter.pdf";
import purchasing from "./resources/A_Purchase.pdf";

import raw from './i18n/locale/he/intro.txt';
import purchasetxt from './i18n/locale/he/purchase.txt';

import './main.css';

import Icon from 'react-icons-kit';
import { close } from 'react-icons-kit/fa/close'

const HMain = ({ t }) => {
     
   
    const [qu ] = useState("");
    const [purchaseon, setPurchaseon] = useState(false);
    const [footerwidth,setFooterwidth] = useState(0);
    const [dummy, setDummy] = useState(1);
    const [showlist, setShowlist] = useState(() => {
        let m = sessionStorage.getItem('cowmenu');
        if (!m || m === "-1") {
            return false;
        } else {
            return true;
        }
    });
    const [introtext, setIntrotext] = useState("");
    const [selectedmenu, setSelectedmenu] = useState(() => {
        let m = sessionStorage.getItem('cowmenu');
        if (!m) {
            sessionStorage.setItem("cowmenu", "-1");
            return -1;
        } else {
            return m;
        }
    });
	 
	window.onbeforeunload = () => {
	   localStorage.clear();
	}
	 
    const documents = [
		{ displayName: "רשימת יהודי פרושוביץ ששבו לעיירה עם שחרורה, תחילת 1945", source: a2 },
        { displayName: "מכתב המלצה שכתב יוסף מורילס למכר מקומי (1945, פולנית) ", source: a1 },
        { displayName: "תרגום מכתב ההמלצה לעברית", source: a5 },
        { displayName: "יוסף, דבורה ומוניאק (משה) ברשימת נוסעי  האוניה תיאודור הרצל שהגיעה לישראל באפריל 1949, בעמוד 89 מתוך 180", source: "https://www.archives.gov.il/archives/Archive/0b0717068001a9a2/File/0b071706806ce56f" },
		{ displayName: "העדות האנונימית לגבי רצח אהרון (עמוד שני מלמטה, מקור, פולנית)", source: a6 },
		{ displayName: "פרסום הסיפור ובקשה לכל מידע, באתר המוזאון להיסטוריה של היהודים בפולין - אפריל 2014", source: "https://sprawiedliwi.org.pl/en/stories-of-rescue/your-stories/seeking-polish-people-or-their-relatives-who-sheltered-my-family-named-moriles-during-ww2" },
		{ displayName: "המכתב שכתב זובלסקי לבלומנפלד, ובו רמזים למיקום הבור (פולנית) ", source: original_Zubalski_letter },
		{ displayName: "המכתב שכתב זובלסקי לבלומנפלד (תרגום לעברית)  ", source: translated_Zubalski_letter },
		{ displayName: "בקשה למידע על האנשים שהסתירו את משפחת מורילס בעת המלחמה - אתר אינטרנט בפרושוביץ, אוקטובר 2020", source: "http://www.24ikp.pl/info/szukam/20201001moriles01/art.php" },
		{ displayName: "בקשה למידע על רצח אהרון באתר אינטרנט בפרושוביץ, נובמבר 2020", source: "http://www.24ikp.pl/info/szukam/20201116moriles02/art.php" },
		{ displayName: "יוסף, ברוניה ומוניאק ברשימת הניצולים, מוזיאון השואה, וושינגטון", source: "https://www.ushmm.org/online/hsv/person_advance_search.php?NameSearch__SourceSrchGrp=&NameSearch__ParentSourceId=&NameSearch__sort=name_primary_sort&NameSearch__MaxPageDocs=10&NameSearch__lname=moryles&NameSearch__lname_accuracy=&NameSearch__fname=&NameSearch__fname_accuracy=&NameSearch__lname_maiden=&NameSearch__lname_maiden_accuracy=&NameSearch__Query=&NameSearch__SourceId=27003&source_list=&NameSearch__year_birth=&NameSearch__year_birth_accuracy=&NameSearch__year_death=&NameSearch__year_death_accuracy=&NameSearch__year=&NameSearch__year_accuracy=&NameSearch__place=&NameSearch__place_accuracy=&NameSearch__place_IsGeoExpand_cb_0=&NameSearch__place_birth=&NameSearch__place_birth_accuracy=&NameSearch__place_birth_IsGeoExpand_cb_0=&NameSearch__place_prewar=&NameSearch__place_prewar_accuracy=&NameSearch__place_prewar_IsGeoExpand_cb_0=&NameSearch__place_wartime=&NameSearch__place_wartime_accuracy=&NameSearch__place_wartime_IsGeoExpand_cb_0=&NameSearch__place_death=&NameSearch__place_death_accuracy=&NameSearch__place_death_IsGeoExpand_cb_0=&NameSearch__meta_333=&NameSearch__meta_259=" },
		{ displayName: "מודעה בעיתון העיר קילצה בדבר חנות המכולת שפתח אברהם מורילס בגורקה קושצ'יובסקה, בעשרה בינואר 1930", source: grocery_store_ad, desc: "grocery_store_ad" },
        { displayName: "העמוד הראשון בעיתון של קילצה בו הופיעה המודעה, ינואר 1930", source: Kielce_paper_1st_page, desc: "bbbb" },
		{ displayName: " טופס הרישום של יוסף אצל בעלות הברית, 1945 (Displaced Person)", source: c11 },
		{ displayName: "רישום הנישואין של שרה ואברהם מורילס, הוריו של יוסף, בשנת 1899", source: c2 }
    ];

    const pictures = [
        { displayName: "פרומה ויוסף מורילס ", source: c1 },
		{ displayName: "ברוניה ומוניאק, 1941 ", source: d2 },
		{ displayName: "הבית בפרושוביץ בו התגוררה משפחת מורילס - רחוב 3 במאי מספר 75", source: c6 },
		{ displayName: "המקום בו היו הבית והרפת שבה בור המסתור", source: c5 },
        { displayName: "כאן עמד בית משפחת מורילס בכפר גורקה קושצ'יובסקה, ולצידו רפת, גורן, לול ושדות", source: c8 },	
		{ displayName: "‘מדרונות הגבעות מתונים, לכן האזור כולו עטוי שדות וגידולים חקלאיים למלוא העין’", source: c10 },
		{ displayName: "יוסף ומוניאק, לנדסברג, 1947 ", source: d1 },
		{ displayName: "ברוניה בביקור אצל יוסף ומוניאק בלוד, כשבועיים לאחר הגעתם ארצה, 1949 ", source: d3 },
		{ displayName: "ברוניה ומוניאק, 1949 ", source: d4 },
		{ displayName: "התמונות שצילם קאמיל קוטיזה (2020) - עליית הגג ", source: b4 },
		{ displayName: "התמונות שצילם קאמיל קוטיזה (2020) - המדרגות לעליית הגג ", source: b5 },
		{ displayName: "התמונות שצילם קאמיל קוטיזה (2020) - בית קוטיזה (1) ", source: b6 },
		{ displayName: "התמונות שצילם קאמיל קוטיזה (2020) - בית קוטיזה (2)  ", source: b7 },
		{ displayName: "התמונות שצילם קאמיל קוטיזה (2020) - בית קוטיזה (3)  ", source: b8 },
        { displayName: "מצבת האחים ליס. הירצחם (יולי 1945) גרם לכל יהודי פרושוביץ לעזוב", source: Lis_brothers_tomb , desc: "ccccc" },
		{ displayName: "החורשה בה נקבר אהרון (1) - תמונה ששלחה קתרינה בשנת 2021", source: c3 },
		{ displayName: "החורשה בה נקבר אהרון (2) - צולם בידי קתרינה, 2021", source: c4 },
		
		{ displayName: "בית הקברות היהודי בפרושוביץ (2016)", source: Proszowice_Jewish_cemetery, desc: "ccccc" },
		{ displayName: "גשר הרכבת הישן בפרושוביץ (צולם ב-2016)", source: c9 },
		{ displayName: "הכנסייה בפרושוביץ", source: c7 }
				
    ];

    const video = [
        { displayName: "ברוניה: הגירוש מבית קוטיזה", source: v1 },
		{ displayName: "ברוניה: אלתורים בלילות בהם לא היה מקום מסתור", source: v2 },
		{ displayName: "ברוניה: מגדל הטבק", source: v3 },
		{ displayName: "ברוניה: בור המחבוא שנחפר ברפת", source: v4 },
		{ displayName: "ברוניה: 'הכי הרבה אכלנו בצל'", source: v5 },
		{ displayName: "מוניאק: יוסף ואהרון נלכדים בידי אנשי מחתרת הא.ק. שמתכוונים להוציאם להורג", source: v6 },
		{ displayName: "מוניאק: יוסף נמלט משוביו", source: v7 },
		{ displayName: "ברוניה: הגירוש הדרמטי מן הבור שברפת", source: v8 },
		{ displayName: "ברוניה: מגדל הטבק מסרב לקבלם, ובאין ברירה - יושבים בסוכות הטבק שבשדה", source: v9 },
		{ displayName: "ברוניה: סימן קולי מוסכם מסייע ליוסף למצוא את היושבים בשדה", source: v10 },
		{ displayName: "ברוניה: שליחויותיה אל פריצים (1)", source: v11 },
		{ displayName: "ברוניה: שליחויותיה אל פריצים (2)", source: v12 },
		{ displayName: "ברוניה: המעט שידעה על ארץ ישראל (לאחר המלחמה)", source: v13 },
		{ displayName: "מוניאק: קרבות רחוב מול ילדים גרמנים בלנדסברג, בעת החזרה מבית הספר", source: v14 }
		
    ];
    
    const reviews = [
        { displayName: "סקירה של אתי סרוסי", source: "https://etiseroussi.wordpress.com/2023/01/06/%D7%9C%D7%9E%D7%98%D7%94-%D7%9E%D7%A4%D7%A8%D7%94-%D7%A2%D7%A8%D7%9F-%D7%9E%D7%95%D7%A8%D7%99%D7%9C%D7%A1/" },
		{ displayName: " 'קרן דותן - בעיתון 'ישראל היום ", source: e1 },
        { displayName: "סקירה של חוי הראל", source: "https://havibooks.blogspot.com/2023/02/blog-post_20.html?m=1" },
		{ displayName: "סקירה של שניר פלג ", source: "https://www.facebook.com/photo/?fbid=896032244803725&set=a.706734847066800" },
		{ displayName: "סקירה של ליאור רודיק ", source: "https://www.facebook.com/photo/?fbid=7744085652292030&set=g.326964094046662" },
		{ displayName: "סקירה של ציפי ג'לינגולד ", source: "https://www.facebook.com/photo?fbid=2617453971729222&set=gm.6286277968115215&idorvanity=326964094046662" },
		{ displayName: "סקירה של אנשילה יצחקי", source: "https://www.facebook.com/photo?fbid=6215102428532830&set=a.1226268897416233" },
		{ displayName: "סקירה של איילת שושן בן עמי", source: "https://www.facebook.com/photo?fbid=10161141817969101&set=gm.5511329378968142&idorvanity=695347127233082" },
		{ displayName: "סקירה של יעל צין", source: "https://www.facebook.com/photo.php?fbid=672611381533838&set=pb.100063549456402.-2207520000.&type=3" },
		{ displayName: "סקירה של אלה ברוק ", source: "https://www.facebook.com/photo?fbid=5232413456862325&set=gm.5528586260575787&idorvanity=695347127233082" },
		{ displayName: "סקירה של חגית שושני ", source: "https://www.facebook.com/photo/?fbid=667050448758246&set=a.517087597087866" },
		{ displayName: "סקירה של שרון בצלאל", source: "https://www.facebook.com/photo/?fbid=10160583864451952&set=gm.5875047962581433&idorvanity=366099626809655" },
		{ displayName: "סקירה של מירי הדר", source: "https://www.facebook.com/groups/realbookreviews/permalink/1391481008251122/" },   
		{ displayName: "סקירה של יפעת ארניה", source: "https://www.facebook.com/photo.php?fbid=792081876288541&set=pb.100064602072629.-2207520000&type=3" }, 
		{ displayName: "סקירה של שולה גולד", source: "https://shulagold.blogspot.com/search?q=%D7%9C%D7%9E%D7%98%D7%94+%D7%9E%D7%A4%D7%A8%D7%94" },  
		{ displayName: "סקירה של אריק בלום", source: "https://www.facebook.com/photo/?fbid=5877576768947485&set=gm.3420801731579504&idorvanity=2027437707582587" }
	        
    ];

    const onMenuSelect = (e, index) => {
        setSelectedmenu(index);
        setShowlist(true);
        sessionStorage.setItem("cowmenu",index);
    }

    const renderLabels = (index, list) => {
        let map = [];
        list.forEach((item, index) => {
            map.push(
                <div key={"doc_" + index} className="HItemContainer">
                    <a href={item.source} key={"doc_" + index} className="HItemLabel">{t(item.displayName)}</a>
                </div>
            );
        });
        return map;
    }

    const renderImages = (index, list) => {
        let map = [];
        list.forEach((item, index) => {
            map.push(
                <div key={"doc_" + index} className="HItemContainer">
                    <a href={item.source} key={"doc_" + index} className="HItemLabel">{t(item.displayName)}</a>

                </div>
            );
        });
        return map;
    }

    const renderVideos = (index, list) => {
        let map = [];
        list.forEach((item, index) => {
            map.push(
                <div key={"doc_" + index} className="HItemContainer">
                    <a href={item.source} key={"doc_" + index} className="HItemLabel">{t(item.displayName)}</a>

                </div>
            );
        });
        return map;
    }

    const renderReviews = (index,list) => {
        let map = [];
        list.forEach((item, index) => {
            map.push(
                <div key={"doc_" + index} className="HItemContainer">
                    <a href={item.source} key={"doc_" + index} className="HItemLabel">{t(item.displayName)}</a>
                </div>
            );
        });
        return map;
    } 

    const renderList = () => {
        let list = documents;
        if (selectedmenu === "1") {
            list = documents;
        } else if (selectedmenu === "2") {
            list = pictures;
        } else if (selectedmenu === "3") {
            list = video;
        } else if (selectedmenu === "4") {
            list = reviews;
        }


        let map = [];
        if (selectedmenu === "1") {
            map.push(<div className="ImageContainer">{renderLabels(selectedmenu, list)}</div>);
        }

        if (selectedmenu === "2") {
            map.push(<div className="ImageContainer">{renderImages(selectedmenu, list)}</div>);
        }
        if (selectedmenu === "3") {
            map.push(<div className="VideoHeader"><p>כל הסרטונים - מהמפגש המשפחתי בשנת 2000 שהחיה את הסיפור</p></div>);
            map.push(<div className="ImageContainer">{renderVideos(selectedmenu, list)}</div>);
        }
        if (selectedmenu === "4") {
            map.push(<div className="ImageContainer">{renderReviews(selectedmenu, list)}</div>);
        }


        return map;
    }

    const closeListPane = (e) => {
        setShowlist(false);
        sessionStorage.removeItem("cowmenu");
        setSelectedmenu(-1);
        setDummy(dummy + 1);
    }

    useEffect(() => {
        if (purchaseon === true) {
            return;
        }
        fetch(raw)
            .then(r => r.text())
            .then(text => {
                setIntrotext(text);
            });
    },[]
    );

    useEffect(() => {
        if (purchaseon === true) {
            fetch(purchasetxt).then(r => r.text())
                .then(text => {
                    setIntrotext(text);
                });
        }
        if (purchaseon === false) {
            fetch(raw)
                .then(r => r.text())
                .then(text => {
                    setIntrotext(text);
                });
        }
    }, [purchaseon]);

    useEffect (()=> {
        window.addEventListener('resize', getPicWidth);
        
    }, []);

    useEffect(() => {
        if (qu.indexOf("purchase") > -1) {
            setPurchaseon(true);
        } else {
            setPurchaseon(false);
        }
    },[qu]);

    const getPicWidth = () => {
        let ele = document.getElementById("BOOKKPICID");
        if (!ele) {
            return;
        }
        let rect = ele.getBoundingClientRect();
        setFooterwidth(rect.bottom - 200);
    }

    const onReviews = (e) => {
        onMenuSelect(e,"4");
        return false;
    }

    return (
        <> <div className="HMainPage">
            <div dummy={dummy} className="HMenuContainer" dir="rtl">
                <label className="HMenuLabel G0">
                    <LangSelector />
                </label>
                <label isselected={"" + ('1' === selectedmenu)} className="HMenuLabel G1" onClick={(e) => { onMenuSelect(e, "1") }}>
                    {t("_documents")}
                </label>
                <label isselected={"" + ('2' === selectedmenu)} className="HMenuLabel G2" onClick={(e) => { onMenuSelect(e, "2") }}>
                    {t("_pictures")}
                </label>
                <label isselected={"" + ('3' === selectedmenu)} className="HMenuLabel G3" onClick={(e) => { onMenuSelect(e, "3") }}>
                    {t("_video")}
                </label>
            </div>
            <img alt="main" id="BOOKKPICID"  src={mainpic} className="CIMAGE ROW-START-2 ROW-END-4 COL-START-1 COL-END-1"/>
            <div className="HTextIntroContainer ROW-START-2  ROW-END-4 COL-START-2 COL-END-3" dir="rtl">
                {purchaseon && <div className="CClosePane"><Icon size="24px" icon={close} onClick={() => {
                    setPurchaseon(false);
                }}/></div>}
                <p className="FIntroTextP" dangerouslySetInnerHTML={{ __html: introtext }}></p>
            </div>
           
            {showlist && <div className="HSelectedDetailsConstainer  ROW-START-2 ROW-END-4 COL-START-2 COL-END-3" style={{left: footerwidth}}>
                <div className="HList" selectedindex={selectedmenu}>
                    <div><Icon icon={close} onClick={(e) => closeListPane(e)} size="20px" className="HCloseIcon" /></div>
                    {renderList()}
                </div>
            </div>}
            <div className="FButtonContainer ROW-START-1 ROW-END-4 COL-3">
                <a  href = {backcover} target = "_blank"  rel="noreferrer" className="FactionButton">גב הספר</a>
                <a  href = {frontcover} target = "_blank"  rel="noreferrer" className="FactionButton">כריכה קדמית</a>
                <label onClick={(e) => onReviews(e)} className="FactionButton">כתבו על הספר</label>
                <a  href = {first_chapter} target = "_blank"  rel="noreferrer" className="FactionButton">פרק ראשון</a>
                <a  href = {purchasing} target = "_blank"  rel="noreferrer" className="FactionButton">לרכישה</a>
               
			</div>
            <div className="HFooter ROW-4 COL-2">
                <a href="mailto:eran@undercow.com" className="Hcontact">{t("Please contact")} </a>
            </div>
        </div>
        </>
    )
};

export default HMain;
