// JavaScript source code
import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './i18n';


const languages = [{ display: 'עברית', key: 'he' }
   ];

class LangSelector extends React.Component {


    changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    onChange = (e) => {
        let selected = e.target.selectedIndex;
        let lang = languages[selected].key;
        this.changeLanguage(lang);
    }

    getSupportedLanguages = () => {
        
        let map = [];
        languages.forEach((val) => {
            map.push(<option key={val.key} className="HSCSLangOption">{val.display}</option>)
        });
        return map;
    }

    render() {
        return (
                <select onChange={this.onChange} id="HSCSLang" key="HSCSLang" className="HSCSLangSelector">
                 {this.getSupportedLanguages()}
                </select>
        )
    }
}

ReactDOM.render(<LangSelector />, document.getElementById('root'));

export default LangSelector;